import React, { Component } from 'react';
import SVG from 'react-inlinesvg';
import { Menu, Drawer } from 'antd';
import { Link } from 'gatsby';
import HamburgerMenu from '@bit/jroehl.drylib.components.hamburger-menu';

import { generateTreeView } from '../utils';
import styles from './header.module.css';

const { SubMenu, Item } = Menu;

export class Header extends Component {
  state = {
    expanded: true,
    drawerOpen: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', ({ pageY = (window.scrollY = window.pageYOffset) }) => {
      const { expanded } = this.state;
      const offset = 16;
      if (expanded && pageY > offset) {
        this.setState({ expanded: false });
      } else if (!expanded && pageY <= offset) {
        this.setState({ expanded: true });
      }
    });
  }

  toggleDrawer = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  generateNav = (treeView, selected, nestedLevel = 0) => {
    const { paths } = this.props;
    return treeView.map(base => {
      if (!base.routes.length)
        return (
          <Item key={base.path} className={selected.includes(base.path) ? styles.customActive : ''}>
            <Link to={base.path}>{paths[base.path].name}</Link>
          </Item>
        );
      return (
        <SubMenu
          key={base.path}
          title={<Link to={base.path}>{paths[base.path].name}</Link>}
          className={selected.includes(base.path) ? styles.customActive : ''}
          onTitleClick={this.handleClick}
        >
          {base.routes.map(route => {
            if (route.routes.length) return this.generateNav([route], selected, nestedLevel);
            return (
              <Item key={route.path} className={selected.includes(route.path) ? styles.customActive : ''}>
                <Link to={route.path}>{paths[route.path].name}</Link>
              </Item>
            );
          })}
        </SubMenu>
      );
    });
  };

  render() {
    const { expanded, drawerOpen } = this.state;
    const parts = typeof window !== 'undefined' && window.location ? window.location.pathname.split('/') : [];
    const selected = parts.reduce((red, _, i) => {
      const sel = parts.slice(0, i + 1).join('/');
      if (!sel) return red;
      return [...red, sel];
    }, []);

    const treeView = generateTreeView(Object.keys(this.props.paths));
    const nav = this.generateNav(treeView, selected);
    return (
      <>
        <div className={`${styles.root} ${styles.desktopNav}`}>
          <SVG className={expanded ? styles.logoExpanded : styles.logo} src="/logo.svg" uniquifyIDs={false}>
            <img src="/logo.png" alt="logo" />
          </SVG>
          <Menu mode="horizontal" selectedKeys={selected}>
            {nav}
          </Menu>
        </div>
        <HamburgerMenu
          onClick={this.toggleDrawer}
          isOpen={drawerOpen}
          isVisible
          colors={{
            open: 'black',
            closed: 'rgba(32, 94, 55, 1)',
          }}
        />
        <Drawer
          className={`${styles.root} ${styles.mobileNav}`}
          title={
            <SVG className={styles.mobileLogo} src="/logo.svg" uniquifyIDs={false}>
              <img src="/logo.png" alt="logo" />
            </SVG>
          }
          placement="left"
          onClose={this.toggleDrawer}
          visible={drawerOpen}
        >
          <Menu mode="inline" selectedKeys={selected}>
            {nav}
          </Menu>
        </Drawer>
      </>
    );
  }
}

Header.propTypes = {};
